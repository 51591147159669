import React, { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

import { login, getCurrentUserToken } from '../services/auth.service'
import { T2KRoutes } from '../types/general.types'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useTranslation } from 'react-i18next'

type Props = {
    setCurrentUserToken: (token: string | undefined) => void
    originRoute: T2KRoutes
}

const Login: React.FC<Props> = ({ setCurrentUserToken, originRoute }) => {
    const navigate: NavigateFunction = useNavigate()
    const [t, _i18n] = useTranslation()

    function Copyright(props: any) {
        return (
            <Typography variant='body2' color='text.secondary' align='center' {...props}>
                {'Copyright © '}
                <Link color='inherit' href='https://text2knowledge.de/'>
                    Text2Knowledge
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        )
    }

    const [correctLogin, setCorrectLogin] = useState<boolean>(true)

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const username = data.get('username') as string
        const password = data.get('password') as string

        login(username, password).then(
            () => {
                setCurrentUserToken(getCurrentUserToken())
                navigate(originRoute)
            },
            (_error) => {
                setCorrectLogin(false)
            },
        )
    }

    const redirectToOrigin = () => {
        console.log(originRoute)
        navigate(originRoute)
    }

    useEffect(() => {
        if (getCurrentUserToken()) {
            redirectToOrigin()
        }
    }, [navigate]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container component='main'>
            <Grid container component='main'>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: '/logo.svg',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component='h1' variant='h5'>
                            {t('Sign in')}
                        </Typography>
                        <Box component='form' noValidate onSubmit={handleLogin} sx={{ mt: 1 }}>
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                id='username'
                                label={t('E-Mail Address')}
                                name='username'
                                autoComplete='username'
                                autoFocus
                            />
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                name='password'
                                label={t('Password')}
                                type='password'
                                id='password'
                                autoComplete='current-password'
                            />
                            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                                {t('Sign in')}
                            </Button>
                            {!correctLogin && <Alert severity='error'>{t('Incorrect username or password')}</Alert>}
                            <Grid container>
                                <Grid item xs>
                                    <Link
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => navigate('/forgot-password')}
                                        variant='body2'
                                    >
                                        {t('Forgot password?')}
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => navigate('/register')}
                                        variant='body2'
                                    >
                                        {t('Do not have an account? Sign up')}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Login
