import axios from 'axios'

const API_URL = (process.env.REACT_APP_API_URL || 'http://localhost:8000/') + '_health'

export const healthCheck = (callback: (x: boolean) => void) => {
        axios.get(API_URL, {timeout: 2000}).then(
                t => {
                    if (t.status == 200) {
                        callback(false)
                    } else {
                        callback(true)
                    }
                }
            )
            .catch(
                _ => callback(true)
            )
}