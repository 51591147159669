import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Container,
    Typography,
} from '@mui/material'

export const UnderMaintenance: React.FC = () => {
    const [t, _i18n] = useTranslation();
    return (
        <Container component='main'>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
                <img
                    src={'/logo.svg'} 
                    width={'20%'} 
                    height={'20%'}
                    alt='Under Maintenance'
                />
                <Typography variant='h3' component='h1' align='center'>
                    {t('Under Maintenance')}
                </Typography>
            </Box>
        </Container>
    )
}